export const FirebaseConfig = {
	"projectId": "love-ride-uk",
	"appId": "1:1093748664806:web:730696eb975d57b4a4ce27",
	"databaseURL": "https://love-ride-uk-default-rtdb.firebaseio.com",
	"storageBucket": "love-ride-uk.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAWxFJ0jSLbU45RE0d9uyLT09WgYxLrF9Y",
	"authDomain": "love-ride-uk.firebaseapp.com",
	"messagingSenderId": "1093748664806",
	"measurementId": "G-NRRQWVDZ51"
};